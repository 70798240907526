/* src/app/common/header/header.component.scss */
:host {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}
.public-section {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fedd46;
}
.public-section-inner-holder {
  max-width: 1170px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.public-section-inner-holder .public-logo-holder {
  text-decoration: none;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 6px;
  padding: 0 13px;
  background-color: #000;
  color: #fff;
}
.public-section-inner-holder .public-logo-holder:hover {
  cursor: pointer;
}
.public-section-inner-holder .public-logo-holder em {
  font-weight: lighter;
}
.main-section {
  height: 87px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid #f3f2f1;
}
.main-section-inner-holder {
  max-width: 1170px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 766px) {
  .main-section {
    background-color: #fedd46;
  }
}
/*# sourceMappingURL=header.component-WMFBHARJ.css.map */
