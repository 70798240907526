import {Component} from '@angular/core'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [ThemeModule]
})
export class HeaderComponent {
}
