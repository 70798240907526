/* src/app/app.component.scss */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 0;
  min-height: 100vh;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
/*# sourceMappingURL=app.component-PUOLQXFG.css.map */
